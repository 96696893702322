import React, { lazy } from 'react'

// Libraries
import { Link } from 'react-router-dom';
import { Col, Container, Navbar, Row, Tab, Tabs } from "react-bootstrap";
import * as Yup from 'yup';
import { AnimatePresence, m } from 'framer-motion';
import { Form, Formik } from 'formik';

// Functions
import { fadeIn, fadeInLeft, fadeInRight, zoomIn } from '../../../Functions/GlobalAnimations';

// Components
import { resetForm, sendEmail } from "../../../Functions/Utilities";
import { Input } from '../../../Components/Form/Form'
import FooterMenu, { Footer } from '../../../Components/Footers/Footer';
import InViewPort from '../../../Components/InViewPort';

// Data
import FooterData from '../../../Components/Footers/FooterData';
import { pricingTable03MonthData, pricingTable03YearData } from "../../../Components/PricingTable/PricingTableData";
import { IconWithTextData_06 } from "../../../Components/IconWithText/IconWithTextData";
import { blogData } from "../../../Components/Blogs/BlogData";

const IconWithText = lazy(() => import('../../../Components/IconWithText/IconWithText'))
// const HamburgerMenu = React.lazy(() => import("../../../Components/Header/Header").then((module) => ({ default: module.HamburgerMenu })))
const Header = React.lazy(() => import("../../../Components/Header/Header").then((module) => ({ default: module.Header })))
const HeaderNav = React.lazy(() => import("../../../Components/Header/Header").then((module) => ({ default: module.HeaderNav })))
const Menu = React.lazy(() => import("../../../Components/Header/Header").then((module) => ({ default: module.Menu })))
// const SearchBar = React.lazy(() => import("../../../Components/Header/Header").then((module) => ({ default: module.SearchBar })))

const Buttons = lazy(() => import('../../../Components/Button/Buttons'))
const InteractiveBanners02 = lazy(() => import('../../../Components/InteractiveBanners/InteractiveBanners02'))
const ProcessStep = lazy(() => import('../../../Components/ProcessStep/ProcessStep'))
const Overlap = lazy(() => import('../../../Components/Overlap/Overlap'))
const PricingTable03 = lazy(() => import('../../../Components/PricingTable/PricingTable03'))
// const ReactCustomScrollbar = lazy(() => import("../../../Components/ReactCustomScrollbar"))
const BlogMasonry = lazy(() => import('../../../Components/Blogs/BlogMasonry'))
const MessageBox = lazy(() => import('../../../Components/MessageBox/MessageBox'))
// const SocialIcons = lazy(() => import("../../../Components/SocialIcon/SocialIcons"))
const SideButtons = lazy(() => import("../../../Components/SideButtons"))
// const StaticInstagram = lazy(() => import('../../../Components/Instagram/StaticInstagram'))
const TiltBox = lazy(() => import('../../../Components/FancyText/FancyText').then(module => ({ default: module.TiltBox })))
const StartupPageBannerSlider = lazy(() => import('./StartupBanner'))

// Filter the blog data category wise
const blogMasonryData = blogData.filter((item) => item.blogType === "masonry").filter(item => item.category.includes("startup"));

const iconWithTextData = [
  {
    icon: "solid-icon-Electricity text-[#27ae60]",
    title: "Electrical Panel Boards Manufacturing",
    content: "Manufacturers of all types of electrical panel boards",
  },
  {
    icon: "solid-icon-Solar text-[#27ae60]",
    title: "Sourcing and Installing Solar Panels",
    content: "Partnered with leading solar panel manufacturers",
  },
  {
    icon: "solid-icon-Support text-[#27ae60]",
    title: "Not just sales, but after sales support",
    content: "We are always there to help you with your needs",
  },
]

const ProcessStepData = [
  {
    title: "Consultation",
    content: "We begin by understanding your needs, assessing your energy requirements, and planning the most suitable panel board or solar installation solution.",
  },
  {
    title: "Design & Implementation",
    content: "Our experts design a tailored solution, fabricate panel boards, or install solar panels with precision and quality.",
  },
  {
    title: "Testing & Delivery",
    content: "Rigorous testing ensures reliability, and we deliver a fully operational system, providing post-installation support.",
  }
]

// const SocialIconsData = [
//   {
//     color: "#3b5998",
//     link: "https://www.facebook.com/",
//     icon: "fab fa-facebook-f"
//   },
//   {
//     color: "#ea4c89",
//     link: "https://dribbble.com/",
//     icon: "fab fa-dribbble"
//   },
//   {
//     color: "#00aced",
//     link: "https://twitter.com/",
//     icon: "fab fa-twitter"
//   },
//   {
//     color: "#fe1f49",
//     link: "https://www.instagram.com/",
//     icon: "fab fa-instagram"
//   },
//   {
//     color: "#0077b5",
//     link: "https://www.linkedin.com/",
//     icon: "fab fa-linkedin-in"
//   },
// ]

const InteractiveBannersData = [
  {
    subtitle: "Flexible",
    title: "Bundle of layout type different layout",
    img: "https://via.placeholder.com/915x716",
    btnTitle: "View information",
    btnLink: "/page/what-we-offer"
  },
  {
    subtitle: "Unique",
    title: "We are delivering beautiful products",
    img: "https://via.placeholder.com/915x716",
    btnTitle: "View information",
    btnLink: "/page/what-we-offer"
  },
  {
    subtitle: "Responsive",
    title: "True responsiveness modern responsive",
    img: "https://via.placeholder.com/915x716",
    btnTitle: "View information",
    btnLink: "/page/what-we-offer"
  },
  {
    subtitle: "Modern",
    title: "Beautiful and modern latest page builder",
    img: "https://via.placeholder.com/915x716",
    btnTitle: "View information",
    btnLink: "/page/what-we-offer"
  },
]

const Footer_Data = [FooterData[0], FooterData[1], FooterData[4], FooterData[3]]

const HomeStartupPage = (props) => {
  return (
    <div style={props.style}>
      {/* Header Start */}
      <Header topSpace={{ md: true }} type="reverse-scroll">
        <HeaderNav fluid="fluid" theme="dark" expand="lg" className="py-[0px] px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
          <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img className="default-logo" width="111" height="36" src='/assets/img/webp/maha-logo-white.png' data-rjs='/assets/img/webp/maha-logo-white.png' alt='logo' />
                <img className="alt-logo" width="111" height="36" src='/assets/img/webp/mahalakshmi-color-no-bg.png' data-rjs='/assets/img/webp/mahalakshmi-color-no-bg.png.png' alt='logo' />
                <img className="mobile-logo" width="111" height="36" src='/assets/img/webp/mahalakshmi-color-no-bg.png' data-rjs='/assets/img/webp/mahalakshmi-color-no-bg.png.png' alt='logo' />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle className="order-last md:ml-[17px] w-[25px] min-h-[15px] inline-block align-middle">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="col-auto px-0 justify-end">
            <Menu {...props} />
          </Navbar.Collapse>
        </HeaderNav>
      </Header>
      {/* Header End */}

      {/* <SideButtons /> */}

      {/* Section Start */}
      <StartupPageBannerSlider />
      {/* Section End */}

      {/* Section Start */}
      <section className="bg-cover bg-center pb-[200px] lg:pb-[160px] md:py-[110px] sm:py-[50px] startup-iconwithtext" style={{ backgroundImage: "url(/assets/images/maha-asset-1.png)" }}>
        <Container>
          <div className="mb-[105px] md:mb-[70px] sm:mb-[50px]">
            <Overlap className="md:mt-0">
              <Row className="justify-center">
                <Col xs={12} sm={9} lg={12} md={12}>
                  <IconWithText grid="row-cols-1 row-cols-lg-3 row-cols-md-2 justify-center gap-y-10 z-10 relative" className="rounded-[4px] flex" theme="icon-with-text-04" data={iconWithTextData} />
                </Col>
              </Row>
            </Overlap>
          </div>
          <Row className="items-end overflow-hidden">
            <m.div className="col-12 col-lg-3 col-md-6 order-1 text-right md:mb-[50px] md:text-center" {...fadeInRight}>
              <div className="text-[70px] text-basecolor font-serif leading-[70px] tracking-[-3px] font-semibold">500+</div>
              <span className="font-serif text-darkgray font-medium uppercase tracking-[2px] block mb-[15px] sm:mb-[5px]">Projects Delivered</span>
              <p className="w-[90%] inline-block sm:w-[60%] xs:w-full">Over 500 successful projects delivered, showcasing our expertise in panel boards and solar installations.</p>
            </m.div>
            <m.div className="col col-lg-6 order-lg-2 order-3 z-0 py-[10px]" {...{ ...fadeIn, transition: { duration: 0.6 } }}>
              <TiltBox>
                <h1
                  className="title cover-background inline-block font-serif mb-0 uppercase bg-darkgray font-semibold tracking-[-10px] text-[300px] lg:text-[300px] leading-[260px] xs:text-[160px] xs:leading-[150px]"
                  style={{ backgroundImage: `url(/assets/images/mahalakshmi-experience.png)` }}>
                  37
                </h1>
                <span className="font-serif text-xlg text-darkgray tracking-[4px] font-semibold uppercase block xs:text-md">Years of experience</span>
              </TiltBox>
            </m.div>
            <m.div className="col-12 col-lg-3 col-md-6 order-2 md:mb-[50px] md:text-center" {...fadeInLeft}>
              <div className="text-[70px] text-basecolor font-serif leading-[70px] tracking-[-3px] font-semibold">20+</div>
              <span className="font-serif text-darkgray font-medium uppercase tracking-[2px] block mb-[15px] sm:mb-[5px]">High skilled people</span>
              <p className="w-[90%] inline-block sm:w-[60%] xs:w-full">Backed by a skilled team of 20+ experts, ensuring top-notch panel board and solar solutions.</p>
            </m.div>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Lazy Load HTML */}
      <InViewPort>
        {/* Section Start */}
        <section className="sm:pt-[50px] mb-[130px] md:mb-[50px] sm:mb-0 inline-block w-full">
          <Overlap>
            <m.img
              {...{ ...zoomIn, transition: { duration: .8 } }}
              className="mx-auto border-[10px] rounded-full p-0 shadow-black w-[170px] h-[170px] border-white box-shadow-large" src="/assets/images/maha-asset-2.jpeg" alt="startup" />
          </Overlap>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <section className="pb-[130px] lg:pb-[90px] md:pb-[75px] sm:py-[50px] overflow-hidden">
          <Container>
          <Row className="items-center justify-center">
              <Col xl={5} lg={6} md={10}>
                <span className="font-serif text-xmd font-medium text-basecolor inline-block mb-[20px] sm:mb-[10px]">Business process</span>
                <h2 className="heading-5 font-serif font-semibold text-darkgray block -tracking-[.5px] mb-20 w-[85%] lg:mb-[80px] md:mb-12 md:w-[80%] sm:w-full">We understand your requirement to deilver future proof product</h2>
                <ProcessStep grid="row-cols-1" theme='process-step-style-02' data={ProcessStepData} animation={fadeIn} />
              </Col>
              <m.div className="offset-xl-1 col-lg-6 md:mt-[50px]" {...fadeInLeft}>
                <div className="-mr-[30vw] relative md:mr-0" >
                  <img src="/assets/images/maha-banner-3.jpeg" width={1109} height={638} className="overflow-hidden" alt="" />
                </div>
              </m.div>
            </Row>
            {/* <Row>
              <m.div className="col mt-32 w-full md:mt-24 home-startup-interactivebanner" {...fadeIn}>
                <InteractiveBanners02
                  grid="opacity-100"
                  carousalOption={{
                    slidesPerView: 1,
                    spaceBetween: 30,
                    loop: true,
                    keyboard: { enabled: true, onlyInViewport: true },
                    autoplay: { delay: 3000, disableOnInteraction: false },
                    breakpoints: { 992: { slidesPerView: 4 }, 768: { slidesPerView: 2 } }
                  }}
                  data={InteractiveBannersData}
                />
              </m.div>
            </Row> */}
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <section className="bg-lightgray py-[10px] lg:py-[10px] md:py-[10px] sm:py-[10px] text-center">
        </section>
        {/* Section End */}




        {/* Footer Start */}
        <Footer className="startup-footer bg-no-repeat bg-right" theme="light" style={{ backgroundImage: "url(/assets/img/webp/home-footer-down-bg.webp)" }}>
          <Container fluid className="xs:opacity-0 xs:hidden">
            <Row>
              <Col className="h-[65px] lg:h-[30px] bg-top bg-no-repeat" style={{ backgroundImage: "url(/assets/img/webp/home-footer-shadow.webp)" }}>
              </Col>
            </Row>
          </Container>
          {/* <div className="py-[4%] lg:py-[7%] md:py-[50px]">
            <Container>
              <Row md={4} className="justify-center gap-y-[25px]">
                <FooterMenu data={Footer_Data} md={3} sm={6} className="xl:px-[15px]" titleClass="capitalize text-dark" />
              </Row>
            </Container>
          </div> */}
          <div className="pt-[1%] pb-[6%] border-t border-[#ffffff1a]">
            <Container>
              <Row>
                <Link to="/" className="col-sm-4 col-12 sm:mb-[20px] flex justify-start xs:justify-center">
                  <img src="/assets/img/webp/mahalakshmi-color-no-bg.png" alt="logo" width="111" height="36" />
                </Link>
                <p className="col-sm-8 col-12 flex justify-end items-center sm:mb-[20px] xs:text-center xs:justify-center mb-0">© {new Date().getFullYear()} Mahalakshmi Industries. Developed by&nbsp;<a aria-label="ThemeZaa link" className="text-darkgray font-sans underline underline-offset-4 font-medium text-base inline-block hover:text-basecolor" href="https://www.enandgate.com/" target="_blank" rel="noreferrer">NandGate IT</a></p>
              </Row>
            </Container>
          </div>
        </Footer>
        {/* Footer End */}
      </InViewPort>
    </div>
  )
}

export default HomeStartupPage